<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" style="display: flex;justify-content: space-between;">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">设备基本信息</span>
                <el-button type="primary" @click="handleExport">导出列表</el-button>
            </div>
            <el-row style="line-height: 40px;">
                <el-col :span="12">设备编码：{{deviceInfo.code || '-'}}</el-col>
                <el-col :span="12">设备名称：{{deviceInfo.name || '-'}}</el-col>
                <el-col :span="12" v-if="deviceInfo.companyId">所属公司：{{companyFormatter(deviceInfo.companyId)}}</el-col>
                <el-col :span="12">设备状态：{{deviceInfo.statusName || '-'}}</el-col>
                <el-col :span="12">区域：{{deviceInfo.locationDesc || '-'}}</el-col>
                <el-col :span="12">位置：{{deviceInfo.address || '-'}}</el-col>
                <el-col :span="12">坐标： 经度 {{deviceInfo.longitude || '-'}}，纬度 {{deviceInfo.latitude || '-'}}</el-col>
                <el-col :span="12">A相传感器地址：{{deviceInfo.phaseA || '-'}}</el-col>
                <el-col :span="12">报警阈值：{{deviceInfo.limitA || '-'}}</el-col>
                <el-col :span="12">B相传感器地址：{{deviceInfo.phaseB || '-'}}</el-col>
                <el-col :span="12">报警阈值：{{deviceInfo.limitB || '-'}}</el-col>
                <el-col :span="12">C相传感器地址：{{deviceInfo.phaseC || '-'}}</el-col>
                <el-col :span="12">报警阈值：{{deviceInfo.limitC || '-'}}</el-col>
            </el-row>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">温度监测</span>
            </div>
            <div style="font-size: 12px;color:#999;margin-bottom: 20px;">
                最后一次更新时间：{{lastInfo.temperature}}
            </div>
            <el-table :data="tempList" border style="width: 100%">
                <el-table-column prop="rowName" align="center" label="" width="100px"></el-table-column>
                <el-table-column prop="dataA" align="center" label="A相"></el-table-column>
                <el-table-column prop="dataB" align="center" label="B相"></el-table-column>
                <el-table-column prop="dataC" align="center" label="C相"></el-table-column>
            </el-table>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">局放监测</span>
            </div>
            <div style="font-size: 12px;color:#999;margin-bottom: 20px;">
                最后一次更新时间：{{lastInfo.discharge}}
            </div>
            <el-table :data="dischargeList" border style="width: 100%">
                <el-table-column prop="rowName" align="center" label="" width="100px"></el-table-column>
                <el-table-column prop="dataA" align="center" label="A相"></el-table-column>
                <el-table-column prop="dataB" align="center" label="B相"></el-table-column>
                <el-table-column prop="dataC" align="center" label="C相"></el-table-column>
            </el-table>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">历史温度和局放明细</span>
            </div>

            <div style="width: 100%;display: flex;">
                <div style="width: calc(100% / 3)">
                    <div style="height: 40px;border: 1px solid #EBEEF5;line-height: 40px;text-align: center;">
                        A相
                    </div>
                    <el-table :data="historyListA" border  v-loading="historyLoadingA" style="height: 437px;">
                        <el-table-column prop="temperature" align="center" label="温度"></el-table-column>
                        <el-table-column prop="discharge" align="center" label="局放"></el-table-column>
                        <el-table-column prop="logtime" align="center" label="日期" width="200px"></el-table-column>
                    </el-table>
                    <el-pagination background layout="total, prev, pager, next" :page-size="20" :total="totalA"
                                :current-page="pageA"
                                @current-change="currentChangeA" style="margin: 20px 0"/>
                </div>

                <div style="width: calc(100% / 3)">
                    <div style="height: 40px;border: 1px solid #EBEEF5;line-height: 40px;text-align: center;">
                        B相
                    </div>
                    <el-table :data="historyListB" border v-loading="historyLoadingB" style="height: 437px;">
                        <el-table-column prop="temperature" align="center" label="温度"></el-table-column>
                        <el-table-column prop="discharge" align="center" label="局放"></el-table-column>
                        <el-table-column prop="logtime" align="center" label="日期" width="200px"></el-table-column>
                    </el-table>
                    <el-pagination background layout="total, prev, pager, next" :page-size="20" :total="totalB"
                                :current-page="pageB"
                                @current-change="currentChangeB" style="margin: 20px 0;"/>
                </div>

                <div style="width: calc(100% / 3)">
                    <div style="height: 40px;border: 1px solid #EBEEF5;line-height: 40px;text-align: center;">
                        C相
                    </div>
                    <el-table :data="historyListC" border v-loading="historyLoadingC" style="height: 437px;">
                        <el-table-column prop="temperature" align="center" label="温度"></el-table-column>
                        <el-table-column prop="discharge" align="center" label="局放"></el-table-column>
                        <el-table-column prop="logtime" align="center" label="日期" width="200px"></el-table-column>
                    </el-table>
                    <el-pagination background layout="total, prev, pager, next" :page-size="20" :total="totalC"
                                :current-page="pageC"
                                @current-change="currentChangeC" style="margin: 20px 0;"/>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import {exportDeviceDetailApi, getDeviceDetailApi, getDeviceSearchBaseApi, getTemperaturePageApi
    , getDischargePageApi} from '@/api'

export default {
        name: 'deviceView',
        data() {
            return {
                id: this.$route.params.id,
                historyLoadingA: false,
                historyLoadingB: false,
                historyLoadingC: false,
                baseInfo: {
                    statuList: [],
                    regionList: [],
                    companyList: []
                },
                deviceInfo: {},
                tempList: [],
                dischargeList: [],
                historyListA: [],
                historyListB: [],
                historyListC: [],
                lastInfo: {},
                pageA: 1,
                pageB: 1,
                pageC: 1,
                totalA: 0,
                totalB: 0,
                totalC: 0,
                page1: 1,
                total1: 0,
                dateArr1: [new Date('2021-01-01 00:00:00'), new Date()]
            }
        },
        mounted() {
            this.getBaseInfo()
            this.getView()
            this.getTemp('')
            this.getDischarge()
        },
        methods: {
            getBaseInfo() {
                getDeviceSearchBaseApi().then(res => {
                    if (res) {
                        this.baseInfo = res.data
                    }
                })
            },
            getView() {
                getDeviceDetailApi({deviceId: this.id}).then(res => {
                    if (res) {
                        this.deviceInfo = res.data.deviceBase
                        this.tempList = res.data.lastAndPeakTemperature
                        this.dischargeList = res.data.lastAndPeakDischarge
                        this.lastInfo = res.data.lastInfo
                    }
                })
            },
            getTemp(phase) {
                debugger
                let page = 1
                switch(phase){
                    case 'A': this.historyLoadingA = true;page = this.pageA;break
                    case 'B': this.historyLoadingB = true;page = this.pageB;break
                    case 'C': this.historyLoadingC = true;page = this.pageC;break
                    default:{
                        this.historyLoadingA = true
                        this.historyLoadingB = true
                        this.historyLoadingC = true
                    }
                }
                getTemperaturePageApi({
                    deviceId: this.id,
                    pageNum: page
                }).then(res => {
                    if (res) {
                        switch(phase){
                            case 'A': {
                                this.historyListA = res.data.pageA.list   
                                this.totalA = res.data.pageA.totalRow
                                this.historyLoadingA = false
                            }break
                            case 'B': {
                                this.historyListB = res.data.pageB.list   
                                this.totalB = res.data.pageB.totalRow
                                this.historyLoadingB = false
                            }break
                            case 'C': {
                                this.historyListC = res.data.pageC.list   
                                this.totalC = res.data.pageC.totalRow
                                this.historyLoadingC = false
                            }break
                            default: {
                                this.historyListA = res.data.pageA.list   
                                this.totalA = res.data.pageA.totalRow
                                this.historyLoadingA = false

                                
                                this.historyListB = res.data.pageB.list   
                                this.totalB = res.data.pageB.totalRow
                                this.historyLoadingB = false

                                
                                this.historyListC = res.data.pageC.list   
                                this.totalC = res.data.pageC.totalRow
                                this.historyLoadingC = false
                            }
                        }
                    }
                })
            },
            changeDate1(){
                this.page1 = 1
                this.getDischarge()
            },
            getDischarge() {
                this.historyLoading1 = true
                getDischargePageApi({
                    deviceId: this.id,
                    pageNum: this.page1,
                    startTime: this.formattedDate(this.dateArr1[0]),
                    endTime: this.formattedDate(this.dateArr1[1]),
                }).then(res => {
                    if (res) {
                        console.log(res)
                        this.historyList1 = res.data.list
                        this.total1 = res.data.totalRow
                    }
                    this.historyLoading1 = false
                })
            },
            formattedDate(date){
                const year = date.getFullYear();  
                const month = (date.getMonth() + 1).toString().padStart(2, '0');  
                const day = date.getDate().toString().padStart(2, '0');  
                const formattedDate = `${year}-${month}-${day}`;  
                return formattedDate
            },
            currentChangeA(page) {
                this.pageA = page
                this.getTemp('A')
            },
            currentChangeB(page) {
                this.pageB = page
                this.getTemp('B')
            },
            currentChangeC(page) {
                this.pageC = page
                this.getTemp('C')
            },
            handleExport() {
                exportDeviceDetailApi({
                    deviceId: this.id
                }).then(res => {
                    if (res) {
                        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = '设备详情数据导出'
                        link.click()
                    }
                })
            },
            companyFormatter(companyId) {
                return this.baseInfo.companyList.length > 0 ? this.baseInfo.companyList.filter(item => item.id === companyId)[0]['name'] : '-'
            }
        }
    }
</script>

<style scoped>

</style>
